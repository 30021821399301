import { Box, Button, IconButton, TextareaAutosize, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useEffect, useState } from 'react';
import MessageService, { SnackbarSeverity } from '../../../../data-access/MessageService';
import LoadingService from '../../../../data-access/LoadingService';
import CommentService from '../../../../data-access/CommentService';
import { createNewComment } from '../../../../util/Models/CommentModel';
import { FieldSettings } from '../OrderInfoBoxTabs/CustomerTab';
import { useEditOrderContext } from '../../../../data-access/Context/EditOrderContext';
import MultiLine from '../../../../ui/MultiLine/MultiLine';

interface Props {
  fieldSettings: FieldSettings;
  userName: string;
}

const CommentField: React.FC<Props> = (props) => {
  const { order, setOrder, textColor } = props.fieldSettings;
  const { editingState, newComment, setNewComment } = useEditOrderContext();

  useEffect(() => {
    sortTable();
  }, []);

  const sortTable = () => {
    order.comments.sort((a, b) => +a.created - +b.created).reverse();
  };

  const showError = (exception: any) => {
    MessageService.showDialog({
      title: 'En fejl opstod',
      message: exception?.message || 'Ukendt fejl'
    });
    LoadingService.showLoader('');
  };

  const deleteComment = async (id: string, index: number) => {
    try {
      let res = false;
      if (index > -1) {
        if (id && id != '') {
          res = await CommentService.deleteComment(id);
        }
        if (res) {
          setOrder({
            ...order,
            comments: order.comments.filter((c) => c.id != id)
          });

          MessageService.showSnackbar({
            message: 'Kommentar slettet med succes',
            severity: SnackbarSeverity.SUCCESS
          });
        }
      }
    } catch (exception) {
      showError(exception);
    }
  };

  const saveComment = async () => {
    try {
      let comment;
      if (order.id.length < 36) {
        comment = createNewComment(newComment, order.id, props.userName);
      } else {
        comment = await CommentService.createComment(
          createNewComment(newComment, order.id, props.userName)
        );
      }

      if (comment) {
        order.comments.unshift(comment);
        setOrder(order);
        setNewComment('');
        if (order.id.length >= 36) {
          MessageService.showSnackbar({
            message: 'Kommentar gemt med succes',
            severity: SnackbarSeverity.SUCCESS
          });
        }
      }
    } catch (exception) {
      showError(exception);
    }
  };

  return (
    <Box borderTop={1} borderColor={'lightgray'}>
      <Typography variant="h6" fontWeight={'bold'} marginTop={'10px'}>
        Seneste kommentarer
      </Typography>
      <Box display={'flex'} flexDirection={'column'}>
        {order.comments.map((comment, index) => (
          <Box key={comment.id} display={'flex'} flexDirection={'column'} marginTop={'15px'}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
              >
                <Typography fontWeight={'bold'} sx={{ marginRight: '15px' }}>
                  {comment.author}
                </Typography>
                <Typography color={textColor}>{comment.created.toLocaleString()}</Typography>
              </Box>
              {editingState && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => deleteComment(comment.id, index)}
                  data-testid={'comment-delete'}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              )}
            </Box>
            <Typography marginTop={0}>
              <MultiLine text={comment.content} />
            </Typography>
          </Box>
        ))}
      </Box>
      {editingState && (
        <Box display={'flex'} flexDirection={'column'} marginTop={'30px'}>
          <TextareaAutosize
            placeholder="Skriv en kommentar..."
            minRows={3}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            data-testid={'comment-text-area'}
          />
          <Box display="flex" flexDirection={'row'} justifyContent={'flex-end'}>
            <Button
              style={{ placeSelf: 'flex-end', marginTop: '10px' }}
              variant={newComment.length > 0 ? 'contained' : 'outlined'}
              onClick={() => saveComment()}
              data-testid={'comment-add'}
              disabled={true}
            >
              TILFØJ
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CommentField;
